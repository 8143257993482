<template>
    <div>
       
        <NavigationBar/>

         <div class="fs-portfolio">
            <div class="fs-page-header">
                <h1>Κατάλογος custom λύσεων</h1>
            </div>
            <div class="fs-page-description">
                <h2>Ολοκληρωμένες, πλήρεις <span class="underline">custom λύσεις</span> Windows (.NET), δικτύου/Web (asp .NET) αλλά και Android κατάλληλες για <span class="underline">κάθε μέγεθος επιχείρισης</span> και κάθε "πορτοφόλι"</h2>
            </div>

            <div class="fs-bio">
                 <h2 class="fs-section-header">ΣΧΕΤΙΚΑ</h2>
                 <div class="fs-bio-content">
                     <div class="fs-bio-image">
                         <img src="@/assets/bioImage-fn.jpg" alt="" srcset="">
                     </div>
                     <div class="fs-bio-info">
                         <h3 class="underline">ΦΥΔΑΝΤΣΗΣ ΝΙΚΟΛΑΟΣ</h3>
                         <p>Σχεδιάζουμε και υλοποιούμε λογισμικό από το 2001 - με δεκάδες custom λύσεις αρχικά σε συνεργασία με την Singular, μετέπειτα τη SingularLogic και πλέον την Epsilon-SingularLogic, αλλά και δεκάδες μικρές και μεγάλες αυτόνομες λύσεις τελευταίας τεχνολογίας και για διάφορες πλατφόρμες (Windows, Web και Android).</p>
                         <p>Δείτε αναλυτικότερα...</p>
                     </div>
                 </div>
            </div>

            <div class="fs-apptypes">
                <div class="fs-apptypes-widget" data-aos="zoom-in" data-aos-duration="2000" data-aos-delay="100">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 339.91 339.76">
                        <path d="M339.71 159.2q-54.9 0-109.7-.1-32.85 0-65.6.1c-2.5 0-3.4-.6-3.4-3.3q.15-63 0-126c0-2.2.3-3.2 2.9-3.6 30.9-4.5 61.8-9.2 92.7-13.8 27.7-4.2 55.4-8.3 83.2-12.5q-.15 79.65-.1 159.2ZM336.41 339.6c-4.3-1.8-9-1.8-13.5-2.5-35.2-5.4-70.5-10.6-105.7-15.9-17.8-2.7-35.6-5.4-53.4-7.9-2.4-.3-2.8-1.2-2.8-3.4 0-40.7.1-81.3 0-122 0-3.1 1.3-3.3 3.7-3.3 56.9.1 113.8 0 170.8 0 1.4 0 2.9-.2 4.3-.2q0 75.9.1 151.9c-.1 2.8-.7 3.9-3.5 3.3ZM68.41 161c-21.6 0-43.1 0-64.7.1-2.7 0-3.8-.4-3.7-3.5q.15-52 0-104.1c0-2.6.7-3.4 3.3-3.8 32.8-4.7 65.5-9.6 98.3-14.5 10.9-1.6 21.8-3.2 32.7-4.9 2.2-.4 2.9 0 2.9 2.4q-.15 62.85 0 125.7c0 3-1.5 2.8-3.5 2.8-21.7-.3-43.5-.2-65.3-.2ZM68.71 184.7c21.7 0 43.3 0 65-.1 2.7 0 3.5.7 3.5 3.5-.1 39.5-.1 78.9 0 118.4 0 2.9-.6 3.4-3.5 2.9q-46.05-6.9-92-13.6c-13.1-1.9-26.2-4-39.3-5.8-1.9-.3-2.3-.9-2.3-2.7 0-33.4.1-66.8 0-100.1 0-2.6 1.3-2.5 3-2.5Z"/>
                    </svg>
                    <div>
                        <h3 class="underline">WINDOWS ΕΦΑΡΜΟΓΕΣ</h3>
                        <p>Ανάπτυξη custom Windows εφαρμογών με χρήση τελευταίας τεχνολογίας .NET</p>
                    </div>
                </div>
                <div class="fs-apptypes-widget" data-aos="zoom-in" data-aos-duration="2000" data-aos-delay="300">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 318.45 318.71">
                        <path d="M318.45 149.51v19.3c-.8 6.5-1.7 13.1-2.5 19.9-7.5-2.5-14.5-5-21.6-7.1-3.3-1-4.7-2.4-3.4-5.8a16.19 16.19 0 0 0 .2-3.3c-6.5 0-12.8.2-19.1-.1a31.52 31.52 0 0 1-9.1-1.5c-25.7-8.4-51.3-17-76.2-25.3h27.5c-1.6-13.4-3.1-26.5-4.6-39.4-12.9 1.2-24.9 2.4-36.9 3.5v36.1h6.8c-17.5 2.9-28.5 12.8-33.3 30.6-.2-1.8-.3-2.8-.4-3.9h-41.5c1.5 13.5 3 26.8 4.6 40.4 1.7-.2 2.8-.4 3.8-.5 8.5-.9 17.1-2 25.6-2.7 7.7-.6 7.8-.3 7.8-8.1v-12a39.77 39.77 0 0 1 2.3 5.9c6.8 20.5 13.5 41.1 20.6 61.5 2.5 7.3 4.1 14.5 3.5 22.1-.2 2.5 0 5 0 8.1 2.1-1.5 3.6-2.6 5.2-3.8 3.6 10.9 7.2 21.5 10.7 32.2a7 7 0 0 1-1.4.3c-6.1.8-12.3 1.6-18.4 2.5h-19.3l-3-.6c-5.6-.8-11.3-1.2-16.9-2.3-39.9-7.9-72.4-27.9-96.9-60.3-28.3-37.4-38.2-79.7-29.5-125.8 8.1-43.1 30.9-77.1 67-101.8 34.9-23.9 73.7-32 115.4-25.4 29.8 4.7 56 17.3 78.6 37.3 28 24.7 45.5 55.5 52 92.3 1 6 1.6 11.8 2.4 17.7Zm-270.9 81.2c12.1-4.3 23.9-8.5 35.2-12.5-1.7-15.2-3.4-30.3-5-45.4h-50.4a131.26 131.26 0 0 0 20.2 57.9Zm35.2-130c-11.4-4.1-23.1-8.2-35.2-12.5a133.45 133.45 0 0 0-20.2 57.5h50.3c1.7-15.2 3.4-30.1 5.1-45Zm188.3-12.5c-12.2 4.3-23.9 8.5-35.2 12.5 1.7 14.9 3.3 29.9 5 44.9h50.4a133 133 0 0 0-20.2-57.4Zm-166.7 57.4h41.5v-35.9c-12.2-1.2-24.2-2.3-37-3.6-1.5 13.1-3 26.2-4.5 39.5Zm41.5-114.3c-16.2 12.9-23.5 30.3-30 49 10.3 1 20 1.9 30 2.8Zm56.3 49.1c-2.9-16.8-20.7-45.6-29.5-48.1v50.8c10-.9 19.7-1.8 29.5-2.7Zm-85.8 158.1c2.7 16.5 20.5 45.4 29.4 48.1v-50.8c-9.8.8-19.5 1.7-29.4 2.7Zm98-199.6c5 11.8 10 23.5 15.2 35.8l24.3-8.2a132.62 132.62 0 0 0-39.5-27.6Zm-125.4 35.8c5.3-12.5 10.3-24.2 15.3-35.8a131.36 131.36 0 0 0-39.5 27.8c8.6 2.8 16.6 5.5 24.2 8Zm-24.3 177.5a131.59 131.59 0 0 0 39.6 27.7c-5-11.7-10-23.5-15.2-35.8-7.6 2.6-15.6 5.2-24.4 8.1Z"/>
                        <path d="M222.05 318.61c-4.2-1.7-6.5-4.9-7.9-9.2q-20.25-61.2-40.7-122.4c-2.4-7.1.9-13.1 7.6-14.1a12.41 12.41 0 0 1 5.4.7q62.1 20.55 124.2 41.3c3.9 1.3 6.2 3.9 7.8 7.4v5c-1.8 4.2-5.1 6.4-9.3 7.9q-25.5 9.6-50.9 19.6a7 7 0 0 0-3.6 3.6q-9.9 25.2-19.5 50.7c-1.6 4.2-3.7 7.8-8 9.6-1.8-.1-3.4-.1-5.1-.1Z"/>
                    </svg>
                    <div>
                        <h3 class="underline">ΔΙΚΤΥΑΚΕΣ ΕΦΑΡΜΟΓΕΣ</h3>
                        <p>Ανάπτυξη και υποστήριξη δικτυακών εφαρμογών (τοπικού δικτύου ή online) και ιστοσελίδων/web shops</p>
                    </div>
                </div>
                <div class="fs-apptypes-widget" data-aos="zoom-in" data-aos-duration="2000" data-aos-delay="700"> 
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.13 338.65">
                        <path d="M103.15 338.65c-6.7-1.9-12.6-5.1-15.5-11.7a29.73 29.73 0 0 1-2.5-11.8c-.3-14.3-.1-28.7-.1-43v-4.3h-13.5c-9.6-.1-14.7-5.2-14.8-14.8-.1-46.2 0-92.4 0-138.6 0-.5.1-1.1.1-1.9h169.3c.1 1.1.2 2.2.2 3.2 0 45.3.1 90.6-.1 135.9a23.43 23.43 0 0 1-2.5 10.4c-2 4-6.2 5.5-10.6 5.7-4.8.1-9.7 0-14.9 0-.1 1.5-.2 2.8-.2 4 0 13.5-.3 26.9.1 40.4.4 12.9-4.2 22.4-18.3 26.4h-6c-14-3.9-18.8-13.5-18.4-26.3.4-13.5.1-27.1.1-40.6 0-1.2-.1-2.3-.2-3.5h-28.1v4.2c0 14.2.2 28.4-.1 42.6a33.67 33.67 0 0 1-2.6 12.1c-2.9 6.6-8.8 9.8-15.6 11.6ZM226.45 98.05H56.65c.9-28.4 12.3-51 35.2-67.9-5.9-5.9-11.7-11.6-17.4-17.4-3.5-3.6-3.8-7.5-.9-10.5 3.1-3.2 7-3 10.8.7 5.4 5.3 10.9 10.6 16.1 16.2 2.1 2.3 3.9 2.5 6.7 1.3q34.35-14.1 68.7 0c2.8 1.2 4.6 1 6.7-1.3 5.3-5.6 10.8-11 16.3-16.4 2-2 4.4-3.4 7.4-2.3a7.16 7.16 0 0 1 5 5.6c.5 2.9-1 5-2.9 7l-17.2 17.2c23 16.8 34.3 39.4 35.3 67.8Zm-120.3-24.6a10.44 10.44 0 0 0 10.5-10.5 10.55 10.55 0 1 0-10.5 10.5Zm70.8 0a10.55 10.55 0 1 0-10.5-10.4 10.5 10.5 0 0 0 10.5 10.4ZM42.45 182.85c0 16.2.1 32.4 0 48.6-.1 11.2-7.6 20.4-17.7 21.9a21.23 21.23 0 0 1-24.6-21.3c-.2-32.6-.2-65.3 0-97.9a21.64 21.64 0 0 1 19.2-21.7c10-1.1 19.9 6.1 22.5 16.5a31.65 31.65 0 0 1 .6 6.9ZM240.65 182.85v-47.9c0-10 5.5-18 14-21.1 14.2-5.1 28.3 5.5 28.4 21.4q.15 34 0 68.1c0 10.7.3 21.4-.5 32s-11.2 18.9-21.2 18.4c-10.9-.6-20.3-9.2-20.5-20-.3-17-.1-33.9-.1-50.9Z"/>
                    </svg>
                    <div>
                        <h3 class="underline">ANDROID APPS</h3>
                        <p>Ανάπτυξη εφαρμογών για κινητά και tablets Android με δυνατότητα χρήσης και εκτός δικτύου</p>
                    </div>
                </div>
            </div>

             <div class="fs-app-container">
                <h2 class="fs-section-header">ΕΝΔΕΙΚΤΙΚΟΣ ΚΑΤΑΛΟΓΟΣ ΛΥΣΕΩΝ</h2>
                <div class="fs-app-grid">
               
                   <router-link :to="{ name: 'SingleProduct', params: { category: 'Custom λύσεις', title: item.name, product: item}}" v-for="item in appData" :key="item.id" :class="{ 'featured' : item.featured}" data-aos="flip-up" data-aos-duration="500">
                      <h3 class="underline">{{item.name}}</h3>
                      <p>{{item.description}}</p>                                                 
                   </router-link>

                </div>
            </div>
         </div>
       

    </div>
</template>

<script>

    import NavigationBar from '@/components/NavigationBar.vue';
    import appData from '@/assets/data/CustomApplications.json';

    export default {
         name: 'Portfolio',
            components: {
                NavigationBar
            },
            data(){
              return{
                  appData: appData
              }
          }
    }
</script>

<style scoped>
.fs-page-header {
    width:100%;
    background-image:url("../assets/heroBackground.jpeg");
    background-size:cover;
    background-position: center center;
    background-attachment: fixed;
    padding:var(--container-padding);
}

.fs-page-header h1 {
    color: white;
}

.fs-portfolio {
    min-height:65rem;
}



.fs-page-description {
    padding:4rem var(--container-padding);
    color:var(--text-color-primary);
    background:url(../assets/pageDescriptionBackground.jpg);
    background-size:cover;
}

.fs-app-container {
    background:url(../assets/productBackground.jpg);
    background-size: cover;
    background-position:center;
}

.fs-bio,.fs-app-container {
    padding:var(--container-padding);
}

.fs-app-grid {
    display:grid;
    grid-template-columns:repeat(auto-fit, minmax(15rem, 1fr));
    gap:3rem;
    
}

.fs-app-grid > .featured {
    grid-column: span 2;
}

.fs-app-grid > a {
    position: relative;
    display:inline-flex;
    flex-direction: column;
    text-decoration: none;
    padding:2rem;
   background:white;
 box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
border-top:4px solid var(--color-tertiary);
    border-radius:30px;
    z-index:1;
}

.fs-app-grid > a::before {
    content:'';
    position: absolute;
    inset: 0;
     border-radius:30px;
    background: var(--color-primary);
    z-index:-1;
    opacity:0;
    transition: opacity .3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.fs-app-grid > a:hover::before {
    opacity:1;
}

.fs-app-grid > a > h3 {
    color: var(--color-primary);
    font-size:1.5rem;
    transition: color .3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.fs-app-grid > a > p {
    color: var(--text-color-primary);
    font-size:.875rem;
    transition: color .3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.fs-app-grid > a:hover > h3 {
    color: white;
}

.fs-app-grid > a:hover > p {
    color: white;
}

 .fs-bio-content {
    display:flex;
    gap:5rem;
    margin-top:2rem;
  }

  .fs-bio-content img {
    max-width:300px;
    width:auto;
    flex: none;
  }

.fs-bio-info h3 {
    font-size:1.5rem;
    margin-bottom:2rem;
    color: var(--color-primary);
}

.fs-bio-info p {
     color:var(--text-color-primary);
   font-size: clamp(0.75rem, 0.6667rem + 0.3704vw, 1rem);
   line-height:1.5;
}

 .fs-apptypes {
      min-height:15rem;
      padding:7rem var(--container-padding);
      background: linear-gradient(90deg, rgba(120,211,244,0.3) 0%, rgba(124,98,232,0.3) 100%);
      box-shadow: inset 0px 0px 100px 100px #FFFFFF;
      background-size:cover;
      background-attachment: fixed;
      display:flex;
      gap:2.5rem;
      position:relative;
  }

   .fs-apptypes-widget {
       width:33.3333%;
       flex:auto;
       display: flex;
       flex-direction: column;
       gap:2.5rem;
       align-items: center;
       justify-content: space-around;
   }

    .fs-apptypes-widget > svg {
        width:6rem;
        height:auto;
        fill: var(--color-primary) !important;
    }

     .fs-apptypes-widget > div {
        text-align: center;
        color:var(--color-primary);
    }


    .fs-apptypes-widget > div p {
        font-size:.875rem;
         color:var(--text-color-primary);
    }

@media screen and (max-width:1195px) {
    .fs-app-grid {
        display:grid;
        grid-template-columns:repeat(auto-fit, minmax(11rem, 1fr));
        gap:3rem;
    }

}

@media screen and (max-width:992px) {
    .fs-app-grid { 
        display: flex;
        flex-direction: column;
        gap:1.5rem;
    }

 .fs-apptypes { 
        flex-direction: column;
        align-items: center;
    }

  .fs-apptypes-widget {
    width:100%;
  }

   .fs-bio-content {
      flex-direction: column;
    }

   
}

@media screen and (max-width:768px) {
   

}
</style>